exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-samples-drag-js": () => import("./../../../src/pages/samples/drag.js" /* webpackChunkName: "component---src-pages-samples-drag-js" */),
  "component---src-pages-samples-table-js": () => import("./../../../src/pages/samples/table.js" /* webpackChunkName: "component---src-pages-samples-table-js" */),
  "component---src-pages-samples-test-js": () => import("./../../../src/pages/samples/test.js" /* webpackChunkName: "component---src-pages-samples-test-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

